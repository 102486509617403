<template>
  <div id="app">
    <!-- 路由占位符 -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  methods: {
    jumpUrl() {
      window.open('https://chat.livechats.chat/?channelId=Bz32On&language=en')
    }
  }
}
</script>

<style lang="less">
#app {
  // background-color: #fff;
  background-color: #21262f;
  .noData {
    text-align: center;
    padding: 50px 0;
    img {
      width: 70px;
    }
  }
  .service {
    position: fixed;
    right: 25px;
    top: 16.5vh;
    z-index: 10;
    border-radius: 50%;
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
    background-color: #1f2a47;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 18px;
    }
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
.el-message-box {
  width: 80% !important;
}
</style>
