import Vue from "vue";
import VueRouter from "vue-router";
const Login = () =>
    import ("../views/account/Login.vue");
const Register = () =>
    import ("../views/account/Register.vue");
const Forgot = () =>
    import ("../views/account/Forgot.vue");
const ForgotPay = () =>
    import ("../views/account/ForgotPay.vue");
const Home = () =>
    import ("../views/Home/Home.vue");
const Market = () =>
    import ("../views/Market/Market.vue");
const Market_search = () =>
    import ("../views/Market/Search.vue");


const TransactionIndex = () =>
    import ("../views/Transaction/Index.vue");
const Transaction_contract = () =>
    import ("../views/Transaction/Contract.vue");
const Transaction_contract_order = () =>
    import ("../views/Transaction/Contract_order.vue");
const Transaction_currency = () =>
    import ("../views/Transaction/Currency.vue");
const Transaction_currency_order = () =>
    import ("../views/Transaction/Currency_order.vue");
const Transaction_seconds_order = () =>
    import ("../views/Transaction/Seconds_order.vue");



const FinancialIndex = () =>
    import ("../views/Financial/Index.vue");
const FundAssets = () =>
    import ("../views/Fund/Assets.vue");
const FundSelect = () =>
    import ("../views/Fund/Select.vue");
const FundReceive = () =>
    import ("../views/Fund/Receive.vue");
const FundReceive_withdraw_record = () =>
    import ("../views/Fund/Receive_withdraw_record.vue");
const FundReceive_recharge_record = () =>
    import ("../views/Fund/Receive_recharge_record.vue");
const Fund_Transfer = () =>
    import ("../views/Fund/Transfer.vue");
const Fund_FundTransfer = () =>
    import ("../views/Fund/FundTransfer.vue");
const Fund_Withdraw = () =>
    import ("../views/Fund/Withdraw.vue");
const Fund_Assets_all_record = () =>
    import ("../views/Fund/Assets_all_record.vue");
const Fund_Assets_record = () =>
    import ("../views/Fund/Assets_record.vue");


const Setting = () =>
    import ("../views/Setting/Setting.vue");
const Notice = () =>
    import ("../views/Setting/Notice.vue");
const NoticeList = () =>
    import ("../views/Setting/NoticeList.vue");
const UserInfo = () =>
    import ("../views/Setting/UserInfo.vue");
const Real_mark = () =>
    import ("../views/Setting/Real_mark.vue");
const Real = () =>
    import ("../views/Setting/Real.vue");
const Wallet = () =>
    import ("../views/Setting/Wallet.vue");
const AddWallet = () =>
    import ("../views/Setting/AddWallet.vue");
const Security = () =>
    import ("../views/Setting/Security.vue");
const Password = () =>
    import ("../views/Setting/Password.vue");
const PayPassword = () =>
    import ("../views/Setting/PayPassword.vue");
const Share = () =>
    import ("../views/Setting/Share.vue");
const Operational_compliance = () =>
    import ("../views/Setting/Operational_compliance.vue");
const Setting_Bank = () =>
    import ("../views/Setting/BankList.vue");
const AddBank = () =>
    import ("../views/Setting/AddBank.vue");
const Follow_Index = () =>
    import ("../views/Follow/Index.vue");
const Ieo = () =>
    import ("../views/Ieo/Ieo.vue");
const Order = () =>
    import ("../views/Ieo/Order.vue");
const Lockming = () =>
    import ("../views/Lockming/Lockming.vue");
const Lockming_buy = () =>
    import ("../views/Lockming/Buy.vue");
const Lockming_order = () =>
    import ("../views/Lockming/Order.vue");
const Invest = () =>
    import ("../views/Invest/Invest.vue");
const Invest_Mine = () =>
    import ("../views/Invest/Mine.vue");
const Blind_box = () =>
    import ("../views/Blind_box/Blind_box.vue");
const Blind_box_voucher = () =>
    import ("../views/Blind_box/Blind_box_voucher.vue");
const Blind_box_record = () =>
    import ("../views/Blind_box/Blind_box_record.vue");
const Guess = () =>
    import ("../views/Guess/Guess.vue");
const Task = () =>
    import ("../views/Task/Task.vue");
const TaskDay = () =>
    import ("../views/Task/DayTask.vue");

    const Trade = () =>
    import ("../views/Home/Trade.vue");




Vue.use(VueRouter);

const routes = [{
        path: "/",
        component: Login,
    },
    {
        path: "/Login",
        component: Login,
    },
    {
        path: "/Register",
        component: Register,
    },
    {
        path: "/Forgot",
        component: Forgot,
    },
    {
        path: "/ForgotPay",
        component: ForgotPay,
    },
    {
        path: "/Home",
        component: Home,
    },
    {
        path: '/Web',
        component: () => import('@/views/Home/Web'),
    },
    {
        path: "/Market",
        component: Market,
    },
    {
        path: "/Market/Search",
        component: Market_search,
    },
    {
        path: "/Transaction/Index",
        component: TransactionIndex,
    },
    {
        path: "/Transaction/Contract",
        component: Transaction_contract,
    },
    {
        path: "/Transaction/Contract_order",
        component: Transaction_contract_order,
    },
    {
        path: "/Transaction/Currency",
        component: Transaction_currency,
    },
    {
        path: "/Transaction/Currency_order",
        component: Transaction_currency_order,
    },
    {
        path: "/Transaction/Seconds_order",
        component: Transaction_seconds_order,
    },


    {
        path: "/Financial/Index",
        component: FinancialIndex,
    },
    {
        path: "/Fund/Assets",
        component: FundAssets,
    },
    {
        path: "/Fund/Select",
        component: FundSelect,
    },
    {
        path: "/Fund/FundReceive",
        component: FundReceive,
    },
    {
        path: "/Fund/Receive_withdraw_record",
        component: FundReceive_withdraw_record,
    },
    {
        path: "/Fund/Receive_recharge_record",
        component: FundReceive_recharge_record,
    },
    {
        path: "/Fund/Transfer",
        component: Fund_Transfer,
    },
    {
        path: "/Fund/FundTransfer",
        component: Fund_FundTransfer,
    },
    {
        path: "/Fund/Withdraw",
        component: Fund_Withdraw,
    },
    {
        path: "/Fund/Assets_all_record",
        component: Fund_Assets_all_record,
    },
    {
        path: "/Fund/Assets_record",
        component: Fund_Assets_record,
    },
    {
        path: "/Setting/Setting",
        component: Setting,
    },
    {
        path: "/Setting/NoticeList",
        component: NoticeList,
    },
    {
        path: "/Setting/Notice",
        component: Notice,
    },
    {
        path: "/Setting/UserInfo",
        component: UserInfo,
    },
    {
        path: "/Setting/Real_mark",
        component: Real_mark,
    },
    {
        path: "/Setting/Real",
        component: Real,
    },
    {
        path: "/Setting/Wallet",
        component: Wallet,
    },
    {
        path: "/Setting/Bank",
        component: Setting_Bank,
    },
    {
        path: "/Setting/AddWallet",
        component: AddWallet,
    },
    {
        path: "/Setting/AddBank",
        component: AddBank,
    },
    {
        path: "/Setting/Security",
        component: Security,
    },
    {
        path: "/Setting/Password",
        component: Password,
    },
    {
        path: "/Setting/PayPassword",
        component: PayPassword,
    },
    {
        path: "/Setting/Share",
        component: Share,
    },
    {
        path: "/Setting/Operational_compliance",
        component: Operational_compliance,
    },
    {
        path: "/Setting/AddBank",
        component: AddBank,
    },
    {
        path: "/Follow/Follow_Index",
        component: Follow_Index,
    },
    {
        path: "/Ieo/Ieo",
        component: Ieo,
    },
    {
        path: "/Ieo/Order",
        component: Order,
    },
    {
        path: "/Lockming/Lockming",
        component: Lockming,
    },
    {
        path: "/Lockming/Buy",
        component: Lockming_buy,
    },
    {
        path: "/Lockming/Order",
        component: Lockming_order,
    },
    {
        path: "/Invest/Invest",
        component: Invest,
    },
    {
        path: "/Invest/Mine",
        component: Invest_Mine,
    },
    {
        path: "/Blind_box",
        component: Blind_box,
    },
    {
        path: "/Blind_box_voucher",
        component: Blind_box_voucher,
    },
    {
        path: "/Blind_box_record",
        component: Blind_box_record,
    },
    {
        path: "/Guess",
        component: Guess,
    },
    {
        path: "/Task",
        component: Task,
    },
    {
        path: "/TaskDay",
        component: TaskDay,
    },
    {
        path: "/Trade",
        component: Trade,
    },

    // Trade
];

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {

    return originalPush.call(this, location).catch(err => err)

}

const router = new VueRouter({
    routes,
});

export default router;
